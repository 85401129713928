<template>
  <div>
    <v-card class="pa-5">
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-row justify-md-start justify-center"
        >
          <span class="d-flex flex-row justify-start align-center">
            <h2>
              {{ $t("multistop_add") }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text rounded-0"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>
                <!-- <ul> -->
                <!-- <li> -->
                {{ $t("multistop_add_tooltip") }}
                <!-- </li> -->
                <!-- </ul> -->
              </span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <ValidationObserver v-slot="{ invalid }">
        <v-form
          spellcheck="false"
          class="d-flex flex-column justify-space-between"
          :style="
            $vuetify.breakpoint.mdAndUp &&
            'min-height: 40rem; max-height: 40rem; overflow: auto;'
          "
          :disabled="loadingTable"
        >
          <!-- paradas -->
          <div class="">
            <draggable
              v-model="multiStop.stops"
              :options="{ handle: '.drag-handle' }"
              @start="multiStop.drag = true"
              @end="handleDragEnd()"
              class="mt-2"
            >
              <v-row
                dense
                class="ma-0 pa-0"
                v-for="(stop, index) in multiStop.stops"
                :key="stop.index"
              >
                <v-col class="ma-0 pa-0">
                  <ValidationProvider
                    :name="
                      index === 0
                        ? 'Origen'
                        : index !== multiStop.length - 1
                        ? `Parada${index + 1}`
                        : 'Destino'
                    "
                    rules="required"
                    v-slot="{ errors }"
                    slim
                  >
                    <vuetify-google-autocomplete
                      :id="`stop-${index}`"
                      :ref="`stop-${index}`"
                      classname="form-control"
                      :label="
                        index === 0
                          ? ES
                            ? 'Origen'
                            : 'Origin'
                          : index + 1 !== multiStop.stops.length
                          ? ES
                            ? `Parada${index}`
                            : `Stop${index}`
                          : ES
                          ? 'Destino'
                          : 'Destiny'
                      "
                      country="ve"
                      outlined
                      dense
                      :error-messages="errors[0] && [$t('Obligatory field')]"
                      v-model="stop.value"
                      :enable-geolocation="true"
                      types=""
                      @placechanged="changeMultipStop"
                      :disabled="handleAutocompleteOrder(index)"
                    >
                      <!-- types="(locations)" -->
                      <template v-slot:prepend-inner>
                        <div>
                          <v-img
                            :src="handleMultiStopIcon(index)"
                            max-width="30"
                          ></v-img>
                        </div>
                      </template>
                      <template v-slot:append-outer>
                        <v-btn
                          icon
                          class="drag-handle"
                          :disabled="!computedAllStopsHaveAddressGeocoded"
                        >
                          <v-icon>{{ icons.mdiDragHorizontalVariant }} </v-icon>
                        </v-btn>
                        <v-btn
                          icon
                          :disabled="multiStop.stops.length <= 3"
                          @click="handleRemoveMultiStop(index)"
                        >
                          <v-icon>{{ icons.mdiClose }} </v-icon>
                        </v-btn>
                      </template>
                    </vuetify-google-autocomplete>
                  </ValidationProvider></v-col
                ></v-row
              >
            </draggable>
            <div>
              <v-row dense class="ma-0 pa-0 mb-2">
                <v-col class="ma-0 pa-0">
                  <v-btn
                    block
                    color="primary"
                    outlined
                    @click="handleAddStopToMultiStop()"
                    :disabled="
                      multiStop.stops.length === 10 ||
                      !computedAllStopsHaveAddressGeocoded
                    "
                  >
                    {{ ES ? "Agregar parada" : "Add stop" }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </div>
          <div>
            <v-divider></v-divider>

            <!-- descripcion -->
            <v-row dense class="ma-0 pa-0 mt-4">
              <v-col cols="12">
                <ValidationProvider
                  :name="$t('trip description')"
                  v-slot="{ errors }"
                  slim
                >
                  <v-text-field
                    v-model="tripComment"
                    :label="$t('trip description')"
                    outlined
                    maxlength="60"
                    dense
                    :error-messages="errors[0]"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <!-- hora y fecha -->
            <v-row dense class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <ValidationProvider
                  :name="$t('Date and Time')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-datetime-picker
                    :label="$t('Date and Time')"
                    v-model="dateTimePickerProps.datetime"
                    dense
                    clearText="Limpiar"
                    okText="Aceptar"
                    :text-field-props="dateTimePickerProps.textFieldProps"
                    :date-picker-props="dateTimePickerProps.dateProps"
                    :time-picker-props="dateTimePickerProps.timeProps"
                    time-format="h:mm a"
                    dateFormat="dd-MM-yyyy"
                    :error-messages="errors[0] && [$t('Obligatory field')]"
                    :disabled="loadingTable"
                  >
                    <template slot="dateIcon">
                      <v-icon>{{ icons.mdiCalendar }}</v-icon>
                    </template>
                    <template slot="timeIcon">
                      <v-icon>{{ icons.mdiClock }}</v-icon>
                    </template>
                    <template slot="actions" slot-scope="{ parent }">
                      <span
                        class="mt-5 d-flex flex-row justify-center"
                        style="width: 100%"
                      >
                        <v-btn
                          class="ml-3"
                          color="primary"
                          outlined
                          @click.native="parent.clearHandler"
                          >{{ $t("Clear") }}
                        </v-btn>
                        <v-btn
                          color="primary"
                          class="ml-4"
                          @click="parent.okHandler"
                          >{{ $t("Accept") }}
                        </v-btn>
                      </span>
                    </template>
                  </v-datetime-picker>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense class="ma-0 pa-0">
              <v-col class="ma-0 pa-0">
                <ValidationProvider
                  :name="$t('Service type')"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-select
                    :items="multiStop.serviceTypeOptions"
                    :label="$t('Service type')"
                    outlined
                    dense
                    v-model="multiStop.serviceType"
                    :error-messages="errors[0] && [$t('Obligatory field')]"
                    item-text="type"
                    item-value="_id"
                    :menu-props="{ contentClass: 'list-style' }"
                    class="d-flex flex-row align-baseline"
                    :disabled="!multiStop.stops[0].value || loadingTable"
                  >
                    <template v-slot:selection="{ item }">
                      <div
                        class="
                          d-flex
                          flex-row
                          justify-space-between
                          font-weight-bold
                        "
                      >
                        <div class="d-flex flex-row align-center">
                          <v-img
                            :src="`${bucketUrl}${item.image}`"
                            max-width="45"
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="grey lighten-5"
                                ></v-progress-circular>
                              </v-row> </template
                          ></v-img>
                          <h4 class="fontUrbanist ml-2">
                            {{ item.type }}
                          </h4>
                          <span class="d-flex align-center ml-2">
                            <v-icon x-small>
                              {{ icons.mdiAccount }}
                            </v-icon>
                            <span class="ml-1">
                              {{ item.capacity }}
                            </span>
                            <span class="ml-1">|</span>
                            <v-icon x-small class="ml-1">
                              {{ icons.mdiBagSuitcase }}
                            </v-icon>
                            <span class="ml-1">
                              {{ item.baggage }}
                            </span>
                          </span>
                        </div>
                      </div>
                    </template>
                    <template v-slot:item="{ item }">
                      <v-img :src="`${bucketUrl}${item.image}`" max-width="60">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                      <v-list-item-content>
                        <v-list-item-title class="fontUrbanist">
                          {{ item.type }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          <span class="d-flex align-center">
                            <v-icon x-small>
                              {{ icons.mdiAccount }}
                            </v-icon>
                            <span class="ml-1">
                              {{ item.capacity }}
                            </span>
                            <span class="ml-1">|</span>
                            <v-icon x-small class="ml-1">
                              {{ icons.mdiBagSuitcase }}
                            </v-icon>
                            <span class="ml-1">
                              {{ item.baggage }}
                            </span>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
          </div>
        </v-form>
      </ValidationObserver>
    </v-card>
  </div>
</template>
<script>
import {
  mdiInformationOutline,
  mdiCalendar,
  mdiClock,
  mdiAccount,
  mdiBagSuitcase,
  mdiDragHorizontalVariant,
  mdiClose,
} from "@mdi/js";
import { mapActions, mapState } from "vuex";
import { gmapApi } from "vue2-google-maps";
import axios from "@/plugins/axios";

import draggable from "vuedraggable";

export default {
  props: {
    loadingTable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiCalendar,
        mdiClock,
        mdiAccount,
        mdiBagSuitcase,
        mdiDragHorizontalVariant,
        mdiClose,
      },
      multiStop: {
        serviceTypeOptions: [],
        serviceType: null,
        stops: [
          {
            value: null,
            addressGeocoded: null,
          },
          {
            value: null,
            addressGeocoded: null,
          },
          {
            value: null,
            addressGeocoded: null,
          },
        ],
        drag: false,
      },
      dateTimePickerProps: {
        nullDatetime: null,
        datetime: new Date(),
        datetimeString: "2019-01-01 12:00",
        formattedDatetime: "09/01/2019 12:00",
        textFieldProps: {
          appendIcon: "mdi-event",
          dense: true,
          outlined: true,
        },
        dateProps: {
          // headerColor: "red",
          min: "",
          max: "",
        },
        timeProps: {
          format: "ampm",
        },
      },
      bucketUrl: process.env.VUE_APP_IMAGE_BUCKET,
      serviceTypeIdName: {},
      city: "",
      scheduleDate: "",
      tripComment: null,
    };
  },
  created() {
    let plusOne = this.dateTimePickerProps.datetime;
    plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
    this.dateTimePickerProps.datetime = plusOne;
    this.setDateTime(this.dateTimePickerProps.datetime);
    this.scheduleDate = plusOne;
    // this.dateTimePickerProps.timeProps.min = plusOne;
    let today = new Date();
    this.dateTimePickerProps.dateProps.min = this.$moment(today)
      .add(-4, "hours")
      .toISOString();
    this.dateTimePickerProps.dateProps.max = this.$moment(today)
      .add(-4, "hours")
      .add(15, "days")
      .toISOString();
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setServiceTypeOrigin",
      "setDateTime",
      "setStateArrayMultiStop",
      "setStateMultiStopChangeInAddress",
      "setStateTripComment",
    ]),
    async getServiceType(payload) {
      this.setLoading(true);
      try {
        const { data } = await axios.post(
          `${process.env.VUE_APP_STAGING}/typelist_for_dispatcher`,
          {
            subAdminCountry: this.countryName,
            latitude: `${payload.lat()}`,
            longitude: `${payload.lng()}`,
          },
          {
            params: {
              only_shipments: 0,
            },
          }
        );
        if (!!data.success) {
          this.multiStop.serviceTypeOptions = [];
          data.citytypes.forEach((type) => {
            this.multiStop.serviceTypeOptions.push({
              type: type.type_details.typename,
              image: type.type_details.type_image_url,
              baggage: type.baggages,
              capacity: type.max_space,
              _id: type._id,
              sequence_no: type.type_details.sequence_no,
            });
          });
          this.multiStop.serviceTypeOptions.sort(
            (a, b) => a.sequence_no - b.sequence_no
          );
          data.citytypes.forEach((type) => {
            this.serviceTypeIdName[`${type._id}`] = type.type_details.typename;
          });
          this.city = data.city_detail.cityname;
        }
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error(error.message);
      }
      this.setLoading(false);
    },
    changeMultipStop(addressData, placeResultData, id) {
      //no hay place id
      if (!placeResultData.place_id) {
        return;
      }
      let [_rest, index] = id.split("-");
      index = parseInt(index, 10); // no era posible pasar el indice
      let self = this;
      console.log(index);

      let geocoder = new this.google.maps.Geocoder();
      geocoder.geocode(
        { placeId: placeResultData.place_id },
        async (results, status) => {
          try {
            self.setLoading(true);
            if (status == (await google.maps.GeocoderStatus.OK)) {
              let [address] = results;

              if (!address) {
                throw new Error("No address in geocoder");
              }
              address.formatted_address =
                placeResultData.name + ", " + address.formatted_address;
              this.multiStop.stops[index].value = address.formatted_address;
              this.multiStop.stops[index].addressGeocoded = address;
              this.setStateMultiStopChangeInAddress({
                changer: !this.stateMultiStopChangeInAddress.changer,
                indexGiven: index,
              });
            }
            self.setLoading(false);
          } catch (error) {
            console.log(error);
          }
        }
      );
    },
    handleAutocompleteOrder(index) {
      if (index === 0) {
        return false;
      }
      return Boolean(!this.multiStop.stops[index - 1].value);
    },
    handleAddStopToMultiStop() {
      if (
        this.multiStop.length === 10 ||
        !this.computedAllStopsHaveAddressGeocoded
      ) {
        return;
      }

      this.multiStop.stops.push({
        value: null,
        addressGeocoded: null,
      });

      const currentLastGmapAutoComplete = `stop-${this.stateArrayMultiStop.length}`;

      this.$nextTick(() => {
        this.$refs[currentLastGmapAutoComplete][0].$refs.textField.focus();
      });
    },
    handleMultiStopIcon(index) {
      if (index === 0) {
        return require("@/views/RunningTrips/assets/IconOriginMap.png");
      } else {
      }
      if (index > 0 && index + 1 !== this.multiStop.stops.length) {
        return require(`@/views/Multistop/assets/Stop${index}.png`);
      } else {
        return require("@/views/RunningTrips/assets/IconDestinationMap.png");
      }
    },
    handleRemoveMultiStop(index) {
      this.multiStop.stops.splice(index, 1);
      this.setStateMultiStopChangeInAddress({
        changer: !this.stateMultiStopChangeInAddress.changer,
        indexGiven: 99,
      });
    },
    handleDragEnd() {
      this.multiStop.drag = false;
      this.setStateMultiStopChangeInAddress({
        changer: !this.stateMultiStopChangeInAddress.changer,
        indexGiven: 99,
      });
    },
  },
  computed: {
    ...mapState("auth", ["countryName", "countryPhoneCode", "_id", "pre_time"]),
    ...mapState([
      "stateServiceTypeOrigin",
      "stateArrayMultiStop",
      "stateMultiStopChangeInAddress",
    ]),
    google: gmapApi,
    computedStateMultiStopOrigin() {
      return this.stateArrayMultiStop[0];
    },
    computedMultiStopStopsOrigin() {
      return this.multiStop.stops[0].addressGeocoded;
    },
    computedAllStopsHaveAddressGeocoded() {
      const allElementsHaveAddressGeocoded = this.multiStop.stops.every(
        (stop) =>
          stop.addressGeocoded !== null &&
          typeof stop.addressGeocoded === "object"
      );
      return allElementsHaveAddressGeocoded;
    },
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
  watch: {
    "dateTimePickerProps.datetime": function (newVal) {
      if (newVal && newVal < this.scheduleDate) {
        this.$dialog.notify.warning(
          this.$t(
            "You've selected an invalid hour, previous to current time. Please select a different hour"
          )
        );
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.setDateTime(this.dateTimePickerProps.datetime);
        this.scheduleDate = plusOne;
      } else if (!newVal) {
        let plusOne = new Date();
        plusOne.setMinutes(plusOne.getMinutes() + this.pre_time);
        this.dateTimePickerProps.datetime = plusOne;
        this.setDateTime(this.dateTimePickerProps.datetime);
        this.scheduleDate = plusOne;
      }
      this.setDateTime(this.dateTimePickerProps.datetime);
    },
    stateServiceTypeOrigin: function (newVal) {
      if (!newVal) {
        this.multiStop.serviceType = null;
      }
    },
    computedStateMultiStopOrigin: {
      async handler(newVal, oldVal) {
        if (
          oldVal &&
          oldVal.formatted_address &&
          newVal !== oldVal &&
          this.stateMultiStop.length >= 2
        ) {
          this.multiStop.serviceType = null;
          await this.getServiceType(newVal.geometry.location);
        }
      },
      deep: true,
    },
    stateMultiStopGenericStop: async function () {
      if (
        this.stateMultiStopGenericStop &&
        this.stateMultiStopGenericStop.geometry
      ) {
        this.multiStop.genericStop =
          this.stateMultiStopGenericStop.formatted_address;
      }
    },
    "multiStop.serviceType": function (newVal) {
      if (newVal) {
        let found = this.multiStop.serviceTypeOptions.find(
          (option) => option._id === newVal
        );
        this.setServiceTypeOrigin(found);
      } else {
        this.setServiceTypeOrigin(null);
      }
    },
    "multiStop.stops": {
      async handler(_newVal, _oldVal) {
        this.setStateArrayMultiStop(_newVal);
      },
      deep: true,
    },
    computedMultiStopStopsOrigin: {
      async handler(newVal, oldVal) {
        if (newVal?.geometry) {
          await this.getServiceType(newVal.geometry.location);
        }
      },
      deep: true,
    },
    tripComment: {
      async handler(newVal) {
        this.setStateTripComment(newVal);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.margin {
  margin-top: -8px;
}
</style>
