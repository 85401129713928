<template>
  <div>
    <v-card class="pa-5">
      <v-row dense>
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-row justify-md-start justify-center"
        >
          <span class="d-flex flex-row justify-start align-center">
            <h2>
              {{ $t("multistop_list") }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text rounded-0"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>
                <!-- <ul> -->
                <!-- <li> -->
                {{ $t("multistop_list_tooltip") }}
                <!-- </li> -->
                <!-- </ul> -->
              </span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <!-- listado nuevo -->
      <v-row dense>
        <v-col
          cols="12"
          v-if="
            computedAllGeocodedStops.length >= 2 &&
            Boolean(stateServiceTypeOrigin)
          "
        >
          <DataTable
            :headers="table.headers"
            :loading="table.tableLoading"
            :items="table.items"
          />
        </v-col>
        <v-col
          cols="12"
          v-else
          class="d-flex flex-row justify-center align-center"
        >
          <v-img
            src="@/views/Multistop/assets/placerNewLight.png"
            max-width="300"
            v-if="!$vuetify.theme.isDark"
          ></v-img>
          <v-img
            src="@/views/Multistop/assets/placerNewDark.png"
            max-width="300"
            v-else
          ></v-img>
        </v-col>
      </v-row>
      <br />
      <br />
      <v-row
        dense
        v-if="
          computedAllGeocodedStops.length >= 3 &&
          Boolean(stateServiceTypeOrigin)
        "
      >
        <v-col
          cols="12"
          md="12"
          class="d-flex flex-row justify-md-start justify-center"
        >
          <span class="d-flex flex-row justify-start align-center">
            <h2>
              {{ $t("multistop_total_resume") }}
            </h2>
            <v-tooltip top :max-width="300">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  medium
                  v-on="on"
                  class="ml-6 color-gray-text rounded-0"
                >
                  {{ icons.mdiInformationOutline }}
                </v-icon>
              </template>
              <span>
                <!-- <ul> -->
                <!-- <li> -->
                {{ $t("multistop_total_resume_tooltip") }}
                <!-- </li> -->
                <!-- </ul> -->
              </span>
            </v-tooltip>
          </span>
        </v-col>
      </v-row>
      <v-row
        dense
        v-if="
          computedAllGeocodedStops.length >= 3 &&
          Boolean(stateServiceTypeOrigin)
        "
      >
        <v-col v-if="!table.tableLoading" cols="12">
          <DataTableTotal
            :headers="tableTotal.headers"
            :items="computedTableTotal"
          />
          <br />
          <v-row dense class="d-flex flex-row justify-center">
            <v-col cols="6" md="4">
              <v-btn color="primary" block @click="downloadInvoice()">
                {{ $t("Download Quote") }}
              </v-btn>
            </v-col>
            <v-col cols="6" md="4">
              <v-btn color="success" block @click="handleMultiStopQuote()">
                {{ $t("multistop_total_quote") }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          cols="12"
          v-else
          class="d-flex flex-row justify-center align-center"
        >
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { mdiInformationOutline, mdiMapMarker, mdiClose } from "@mdi/js";
import { mapActions, mapState } from "vuex";
import DataTable from "./DataTable.vue";
import DataTableTotal from "./DataTableTotal.vue";

import { gmapApi } from "vue2-google-maps";
import axios from "@/plugins/axios";
import {
  getRouteDistanceAndDurationOpenStreet,
  getRouteDistanceAndDurationGoogleMaps,
} from "@/api/timeDistanceCalculation";

export default {
  components: { DataTable, DataTableTotal },
  data() {
    return {
      icons: {
        mdiInformationOutline,
        mdiMapMarker,
        mdiClose,
      },
      table: {
        tableLoading: false,
        headers: [
          { text: "STOP", value: "stopNumber", align: "center" },
          { text: "DIRECCIÓN DE ORIGEN", value: "stopOrigin", align: "start" },
          {
            text: "DIRECCIÓN DE DESTINO",
            value: "stopDestination",
            align: "start",
          },
          // {
          //   text: "ESTIMATED TIME",
          //   value: "stopTime",
          //   align: "end",
          // },
          // {
          //   text: "DISTANCIA ESTIMADA",
          //   value: "stopDistance",
          //   align: "end",
          // },
          {
            text: "STOP COST",
            value: "costStop",
            align: "end",
          },
          {
            text: "FEE",
            value: "costFee",
            align: "end",
          },
          {
            text: "TOTAL STOP",
            value: "costTotal",
            align: "end",
          },
          // {
          //   text: "ACTION",
          //   value: "actions",
          //   align: "center",
          // },
        ],
        items: [],
      },
      tableTotal: {
        headers: [
          { text: "STOP QUANTITY", value: "stops", align: "center" },
          {
            text: "ESTIMATED TIME",
            value: "stopTime",
            align: "center",
          },
          {
            text: "DISTANCIA ESTIMADA",
            value: "stopDistance",
            align: "center",
          },
          {
            text: "COSTO MULTIPARADA",
            value: "costStop",
            align: "center",
          },
          { text: "FECHA Y HORA", value: "date", align: "start", width: 180 },
          {
            text: "SERVICIO",
            value: "serviceType",
            align: "center",
          },
        ],
      },
      object: null,
      estimatedMultiStop: {},
    };
  },
  methods: {
    getRouteDistanceAndDurationOpenStreet,
    getRouteDistanceAndDurationGoogleMaps,
    ...mapActions(["setLoading"]),
    ...mapActions("auth", ["meNotLoading"]),

    async handleMultiStopQuoteAndtable() {
      if (!this.stateServiceTypeOrigin) {
        return;
      }
      this.table.tableLoading = true;
      await this.meNotLoading();
      try {
        const calculationResults = await this.calculateMultiStopDistances();
        if (!calculationResults) {
          throw new Error("Error in calculations");
        }
        await this.updateTable(calculationResults);
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error(error.message);
      } finally {
        this.table.tableLoading = false;
      }
    },
    async calculateMultiStopDistances() {
      // Check for invalid stateMultiStop array
      const isValidStop = (stop) => {
        const { addressGeocoded } = stop;
        return (
          addressGeocoded &&
          addressGeocoded.geometry &&
          addressGeocoded.geometry.location &&
          addressGeocoded.geometry.location.lat &&
          addressGeocoded.geometry.location.lng &&
          addressGeocoded.formatted_address
        );
      };

      if (
        !Array.isArray(this.computedAllGeocodedStops) ||
        this.computedAllGeocodedStops.length < 2 ||
        this.computedAllGeocodedStops.some((stop) => !isValidStop(stop))
      ) {
        throw new Error("Invalid stateMultiStop array");
      }

      // No hay flota seleccionada
      if (!this.stateServiceTypeOrigin) {
        return;
      }

      const handleDistanceCalculationError = (error) => {
        console.error(error);
        this.$dialog.notify.eroor(`Error in calculations: ${error.message}`);
      };

      const convertGeocodedToLatLng = (addressGeocoded) => ({
        lat: parseFloat(addressGeocoded.geometry.location.lat()),
        lng: parseFloat(addressGeocoded.geometry.location.lng()),
        address: addressGeocoded.formatted_address,
      });

      const promises = this.computedAllGeocodedStops
        .slice(0, -1)
        .map(async (stop, index) => {
          const originG = convertGeocodedToLatLng(
            this.computedAllGeocodedStops[index].addressGeocoded
          );
          const destinationG = convertGeocodedToLatLng(
            this.computedAllGeocodedStops[index + 1].addressGeocoded
          );

          try {
            const result = this.is_used_google_maps_service
              ? await getRouteDistanceAndDurationGoogleMaps(
                  originG,
                  destinationG,
                  this.google
                )
              : await getRouteDistanceAndDurationOpenStreet(
                  originG,
                  destinationG
                );

            return {
              distance: parseFloat(result.distance).toFixed(2),
              duration: result.duration,
              origin: { ...originG },
              destination: { ...destinationG },
            };
          } catch (error) {
            handleDistanceCalculationError(error);
            return null;
          }
        });

      const results = await Promise.allSettled(promises);
      const multiStopFareEstimateArray = results
        .filter(({ status }) => status === "fulfilled")
        .map(({ value }) => value);

      const multiStopFareEstimateArrayBad = results
        .filter(({ status }) => status === "rejected")
        .map(({ reason }) => reason);

      return multiStopFareEstimateArrayBad.length === 0
        ? multiStopFareEstimateArray
        : null;
    },
    async updateTable(multiStopFareEstimateArray) {
      if (!this.stateServiceTypeOrigin) {
        return;
      }

      if (multiStopFareEstimateArray.length === 0) {
        throw new Error("Error in calculations, array empty");
      }

      const routesPayload = this.generateRoutesPayload(
        multiStopFareEstimateArray
      );

      try {
        const apiResponse = await this.callFareEstimatesAPI(routesPayload);
        this.handleApiResponse(apiResponse);
      } catch (error) {
        this.handleApiError(error);
      }
    },

    generateRoutesPayload(multiStopFareEstimateArray) {
      return multiStopFareEstimateArray.map(
        ({ distance, duration, origin, destination }) => ({
          dest_latitude: destination.lat,
          dest_longitude: destination.lng,
          distance,
          duration,
          latitude: origin.lat,
          longitude: origin.lng,
          source_address: origin.address,
          destination_address: destination.address,
        })
      );
    },

    async callFareEstimatesAPI(routesPayload) {
      const apiUrl = `${process.env.VUE_APP_API_URL}/fare_estimates`;

      const UTC_VZLA_DIFF = -4;

      const SERVER_TIMEZONE = "UTC";

      return await axios.post(apiUrl, {
        service_type_id: this.stateServiceTypeOrigin._id,
        time: this.$moment(new Date(this.stateDateTime))
          .add(UTC_VZLA_DIFF, "hours")
          .toISOString(),
        timezone: SERVER_TIMEZONE,
        routes: routesPayload,
      });
    },

    handleApiResponse(apiResponse) {
      if (!apiResponse.data.routes) {
        throw new Error("Error in fare_estimates");
      }

      this.estimatedMultiStop = apiResponse.data;
      this.updateTableItems(apiResponse.data.routes);
    },

    updateTableItems(routes) {
      this.table.items = routes.map((route, index) => ({
        stopNumber: index + 1,
        stopOrigin:
          this.stateArrayMultiStop[index].addressGeocoded.formatted_address,
        stopDestination:
          this.stateArrayMultiStop[index + 1].addressGeocoded.formatted_address,
        stopTime: `${Math.ceil(route.time)}m`,
        stopTimeOnly: Math.ceil(route.time),
        stopDistance: `${route.distance}Km`,
        stopDistanceOnly: route.distance * 1,
        costStop: this.$options.filters.currency(route.base_estimated),
        costStopOnly: route.base_estimated,
        costFee: this.$options.filters.currency(route.multi_stops_fee),
        costTotal: this.$options.filters.currency(route.estimated_fare),
        costTotalOnly: route.estimated_fare,
      }));
    },

    handleApiError(error) {
      console.info(error);
      this.$dialog.notify.error(error.message);
    },

    async handleMultiStopQuote() {
      try {
        this.setLoading(true);

        const { surge_multiplier, is_fixed_fare } = this.estimatedMultiStop;

        const params = this.buildQuoteParams(surge_multiplier, is_fixed_fare);
        console.log(
          "🚩🚩🚩- DM ~ file: MultiStopTable.vue:441 ~ handleMultiStopQuote ~ params:",
          params
        );
        params.trip_comment = this.stateTripComment;

        const quoteResponse = await this.postQuoteRequest(params);

        if (quoteResponse.data.success) {
          console.log(quoteResponse.data);
          this.handleQuoteSuccess();
        } else {
          throw new Error(quoteResponse.data.message);
        }
      } catch (error) {
        this.handleQuoteError(error.message);
      } finally {
        this.setLoading(false);
      }
    },

    buildQuoteParams(surgeMultiplier, isFixedFare) {
      const SERVER_TIMEZONE = "America/Caracas";
      const PAYMENT_MODE = 10;
      const TRIP_TYPE = 7;

      return {
        surge_multiplier: surgeMultiplier,
        is_fixed_fare: isFixedFare,
        payment_mode: PAYMENT_MODE,
        service_type_id: this.stateServiceTypeOrigin._id,
        timezone: SERVER_TIMEZONE,
        start_time: this.calculateStartTime(),
        trip_type: TRIP_TYPE,
        user_type_id: this._id,
        routes: this.estimatedMultiStop.routes.map((route) => {
          return {
            ...route,
            distance: +route.distance,
          };
        }),
      };
    },

    calculateStartTime() {
      const UTC_VZLA_DIFF = -4;
      // const UTC_VZLA_DIFF = 0;
      return this.$moment(new Date(this.stateDateTime))
        .add(UTC_VZLA_DIFF, "hours")
        .toISOString();
    },

    async postQuoteRequest(params) {
      const apiUrl = `${process.env.VUE_APP_API_URL}/quotes`;

      return await axios.post(apiUrl, params);
    },

    handleQuoteSuccess() {
      this.$dialog.notify.success(this.$t("multistop_quote_success"));
      this.$router.push({ name: "multiStop" });
    },

    handleQuoteError(errorMessage) {
      console.error(errorMessage);
      this.$dialog.notify.error(errorMessage.message);
    },
    async handleMultiStopQuoteAndTable() {
      this.table.tableLoading = true;
      await this.meNotLoading();
      try {
        const calculationResults = await this.calculateMultiStopDistances();
        if (!calculationResults) {
          throw new Error("Error in calculations");
        }
        await this.updateTable(calculationResults);
      } catch (error) {
        console.error(error);
        this.$dialog.notify.error(error.message);
      } finally {
        this.table.tableLoading = false;
      }
    },
    async handleWatch() {
      if (
        this.computedAllGeocodedStops.length <= 1 ||
        !Boolean(this.stateServiceTypeOrigin)
      ) {
        return;
      }
      this.$emit("loadingTable", true);
      await this.handleMultiStopQuoteAndTable();
      this.$emit("loadingTable", false);
    },
    downloadInvoice() {
      this.$emit("downloadQuotePDF");
    },
  },
  computed: {
    ...mapState([
      "stateDateTime",
      "stateServiceTypeOrigin",
      "stateArrayMultiStop",
      "stateMultiStopChangeInAddress",
      "stateTripComment",
    ]),
    ...mapState("auth", [
      "countryName",
      "countryPhoneCode",
      "_id",
      "pre_time",
      "wallet",
      "unique_id",
      "is_used_google_maps_service",
    ]),
    google: gmapApi,
    computedTableTotal() {
      const formatValue = (value, format) => {
        const typeMapping = {
          m: Math.ceil, //minutos
          Km: (value) => parseFloat(value).toFixed(2),
          currency: (value) => this.$options.filters.currency(value),
          default: (value) => value,
        };

        const formatter = typeMapping[format] || typeMapping["default"];
        return formatter(value);
      };

      const calculateTotal = (property, format) => {
        return formatValue(
          this.table.items.reduce(
            (accumulator, object) => accumulator + object[property],
            0
          ),
          format
        );
      };

      const convertMinutesToHoursAndMinutes = (minutes) => {
        if (isNaN(minutes) || minutes < 0) {
          throw new Error(
            "Invalid input. Please provide a non-negative number of minutes."
          );
        }

        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        if (hours === 0) {
          return `${remainingMinutes}m`;
        } else if (remainingMinutes === 0) {
          return `${hours}h`;
        } else {
          return `${hours}h ${remainingMinutes}m`;
        }
      };

      if (
        this.computedAllGeocodedStops &&
        this.computedAllGeocodedStops.length >= 3
      ) {
        const totalStopTime = convertMinutesToHoursAndMinutes(
          calculateTotal("stopTimeOnly", "m")
        );
        const totalStopDistance = calculateTotal("stopDistanceOnly", "Km");
        const totalCostStop = calculateTotal("costTotalOnly", "currency");

        this.$emit("updateQuote", {
          "Tipo de viaje": this.stateServiceTypeOrigin,
          "Fecha y hora": this.$moment(new Date(this.stateDateTime))
            .locale("es")
            .format("DD/MM/YYYY HH:mm"),
          "Dirección de origen": this.table.items?.[0]?.stopOrigin,
          "Dirección de destino":
            this.table.items?.[this.table.items.length - 1]?.stopDestination,
          "Tipo de servicio": {
            type_image_url: this.stateServiceTypeOrigin.image,
            typename: this.stateServiceTypeOrigin.type,
          },
          Sobredemanda: Boolean(this.estimatedMultiStop.is_surge_hours)
            ? `${this.estimatedMultiStop.surge_multiplier}x`
            : "No",
          DISTANCIA: totalStopDistance + "Km",
          DURACIÓN: totalStopTime,
          TOTAL: totalCostStop,
          routes: this.table.items,
        });
        return [
          {
            stops: this.table.items.length,
            stopTime: totalStopTime,
            stopDistance: `${totalStopDistance}Km`,
            costStop: totalCostStop,
            date: this.stateDateTime,
            serviceType: this.stateServiceTypeOrigin,
          },
        ];
      } else {
        return [];
      }
    },
    computedAllStopsHaveAddressGeocoded() {
      const allElementsHaveAddressGeocoded = this.stateArrayMultiStop.every(
        (stop) =>
          stop.addressGeocoded !== null &&
          typeof stop.addressGeocoded === "object"
      );
      return allElementsHaveAddressGeocoded;
    },
    computedAllGeocodedStops() {
      return this.stateArrayMultiStop.filter(
        (stop) =>
          stop.addressGeocoded !== null &&
          typeof stop.addressGeocoded === "object"
      );
    },
  },
  watch: {
    stateServiceTypeOrigin: {
      async handler(_newVal, _oldVal) {
        this.handleWatch();
      },
      deep: true,
    },
    stateDateTime: {
      async handler(_newVal, _oldVal) {
        this.handleWatch();
      },
      deep: true,
    },
    stateMultiStopChangeInAddress: {
      async handler(_newVal, _oldVal) {
        this.handleWatch();
      },
      deep: true,
    },
  },
  created() {
    this.estimatedMultiStop = {};
  },
};
</script>
<style lang="scss" scoped></style>
